import { createApp } from 'vue';
import App from './App.vue';

import Router from './router/router';

import './utils/font-size';
import './style/global.less';

import I18n from './locales';
import Toast from './components/toast/index';

const app = createApp(App);

app.use(Router);
app.use(I18n);
app.use(Toast);

app.mount('#app');

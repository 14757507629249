import { createI18n } from 'vue-i18n';

import zh from './zh';
import en from './en';
import ja from './ja';
import es from './es';
import pt from './pt';
import ru from './ru';

const i18n = createI18n({
    locale: 'zh',
    fallbackLocale: 'en',
    messages: {
        zh,
        en,
        ru,
        ja,
        es,
        pt
    }
});

export default i18n;

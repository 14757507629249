import { createApp, h, render } from 'vue';
import Toast from './toast.vue';

const LayerToastId = 'toast-wrapper';
export default {
    install(app) {
        let isToast = false;
        app.config.globalProperties.$toast = async message => {
            if (!isToast) {
                isToast = true;

                let ToastEl = document.getElementById(LayerToastId);
                ToastEl = document.createElement('div');
                ToastEl.setAttribute('id', LayerToastId);
                document.body.appendChild(ToastEl);

                const ToastInstance = h(Toast);
                render(ToastInstance, ToastEl);

                await ToastInstance.component.exposed.show(message);
                isToast = false;
                document.body.removeChild(ToastEl);
                ToastEl = null;
            }
        };
    }
};

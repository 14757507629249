<script setup></script>
<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>
<style lang="scss">
html,
body,
#app {
    width: 100%;
    overflow-x: hidden;
}
body {
    font-family: 'Microsoft YaHei', sans-serif;
    margin: 0;
    text-align: center;
}
</style>

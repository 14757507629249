import {createRouter, createWebHashHistory } from 'vue-router';

const routes = [
    { path: '/', component: () => import('@/views/home/index.vue') },
    { path: '/dragon_invite', component: () => import('@/views/dragon_invite/index.vue') }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes
});

export default router;

/**
 * rem适配
 * 根据设计图的宽度设置designWidth
 * 设计图中所有的px除以100，得到rem，用于css中
 */

(function (self) {
    var designWidth = 750; //设计图宽度

    //
    function setFontSize() {
        var winWidth =
            (document.documentElement && document.documentElement.clientWidth) || self.innerWidth;
        var fontSize = winWidth / (designWidth / 100);
        var el = document.getElementsByTagName('html')[0];

        //
        if (winWidth > 1024) {
            fontSize = 100;
        }

        el.style.fontSize = fontSize + 'px';
        self.fontSize = fontSize;
    }

    if (self.addEventListener) {
        self.addEventListener('resize', setFontSize);
    } else {
        self.onresize = setFontSize;
    }

    setFontSize();
})(window);

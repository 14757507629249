<template>
    <div class="toast" v-if="message">{{ message }}</div>
</template>

<script setup>
import { ref } from 'vue';
const message = ref('');
const show = ({ message: msg, duration = 3000 }) => {
    return new Promise((resolve, reject) => {
        if (!message) {
            reject();
        }
        message.value = msg;
        setTimeout(() => {
            message.value = '';
            resolve();
        }, duration);
    });
};
defineExpose({
    show
});
</script>

<style lang="less" scoped>
.toast {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 32px;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    font-size: 24px;
    opacity: 1;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    &.show {
        opacity: 1;
    }
}
</style>
